import React from 'react'
import { Link } from 'gatsby'

const SingleWhatsNewReleaseBody = ({ frontmatter, html }) => {
  return (
    <section className="singleWhatsNewReleaseBody">
      <div className="singleWhatsNewReleaseBody__breadcrum">
        <Link to="/" className="parent-page">
          Home
        </Link>
        <span>{'>'}</span>
        <Link to="/whats-new/" className="parent-page">
          What's New
        </Link>

        <span>{'>'}</span>
        <span className="current-page">
          {frontmatter?.title || 'release-item'}
        </span>
      </div>

      <h1 className="singleWhatsNewReleaseBody__title">{frontmatter?.title}</h1>
      <p className="singleWhatsNewReleaseBody__date"> {frontmatter?.date} </p>
      <img
        className="singleWhatsNewReleaseBody__img"
        src={frontmatter?.featuredimage?.image}
        alt={frontmatter.title}
      />
      <div
        className="singleWhatsNewReleaseBody__description"
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    </section>
  )
}

export default SingleWhatsNewReleaseBody
